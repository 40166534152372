import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { appUsersInitialState } from "./states";
import { RootState } from "../../store";
import axios, { AxiosError } from "axios";

const initialState = appUsersInitialState;

interface FetchUsersInput {
  successCallback: () => void;
  errorCallback: (error: string) => void;
  value: object;
}

export const fetchUsersAsync = createAsyncThunk(
  "fetchUsers/async",
  async (input: FetchUsersInput, thunkApi) => {
    const { successCallback, errorCallback } = input;
    const { rejectWithValue } = thunkApi;
    const state = thunkApi.getState() as unknown as RootState;
    const admin = state.user;
    const user = state.users;
    const config = {
      headers: {
        authorization: `Bearer ${admin?.userDetails?.token}`
      }
    };
    try {
      const users = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/users/getAllUsers?search=${user.searchString}`,
        config
      );
      successCallback();
      return users.data.content;
    } catch (error) {
      const errorMsg = (error as AxiosError).message || "Unknown Error";
      errorCallback(errorMsg);
      return rejectWithValue(error);
    }
  }
);

const appUsersSlice = createSlice({
  name: "appUsers",
  initialState,
  reducers: {
    searchUsers: (state, action) => {
      state.searchString = action.payload;
    },
    setPageNumber: (state, action) => {
      state.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsersAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUsersAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    });
    builder.addCase(fetchUsersAsync.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const { searchUsers, setPageNumber } = appUsersSlice.actions;

export default appUsersSlice.reducer;
