// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_sidebar__-jiIa {
  width: 15%;
  height: 100vh;
  position: fixed;
  border-right: 1px solid var(--gray-gray-300, #e1e3ea);
  padding: 1.5rem 1rem;
  background: #fff;
}
.sidebar_sidebar__-jiIa .sidebar_tabsSection__uwQ5E {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.sidebar_sidebar__-jiIa .sidebar_tabsSection__uwQ5E .sidebar_tab__-2vlH {
  padding: 8px 16px;
  display: flex;
  gap: 1.25rem;
  border-radius: 6px;
  cursor: pointer;
}
.sidebar_sidebar__-jiIa .sidebar_tabsSection__uwQ5E .sidebar_tab__-2vlH > p {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
  color: var(--gray-gray-700, #5e6278);
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/sidebar.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,aAAA;EACA,eAAA;EACA,qDAAA;EACA,oBAAA;EACA,gBAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAEJ;AADI;EACE,iBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAGN;AAFM;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,oCAAA;AAIR","sourcesContent":[".sidebar {\n  width: 15%;\n  height: 100vh;\n  position: fixed;\n  border-right: 1px solid var(--gray-gray-300, #e1e3ea);\n  padding: 1.5rem 1rem;\n  background: #fff;\n  .tabsSection {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    .tab {\n      padding: 8px 16px;\n      display: flex;\n      gap: 1.25rem;\n      border-radius: 6px;\n      cursor: pointer;\n      & > p {\n        font-size: 1rem;\n        font-weight: 600;\n        line-height: 1.2rem;\n        color: var(--gray-gray-700, #5e6278);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `sidebar_sidebar__-jiIa`,
	"tabsSection": `sidebar_tabsSection__uwQ5E`,
	"tab": `sidebar_tab__-2vlH`
};
export default ___CSS_LOADER_EXPORT___;
