import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./utils/Redux/userSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storeReducer from "./utils/Redux/storesSlice";
import promotionsReducer from "./utils/Redux/promotionsSlice";
import appUsersReducer from "./utils/Redux/appUsersSlice";
import ordersReducer from "./utils/Redux/ordersSlice";
import receiptsReducers from "./utils/Redux/receiptsSlice";
import bannersReducers from "./utils/Redux/bannersSlice";

const rootReducer = combineReducers({
  user: userReducer,
  store: storeReducer,
  promotions: promotionsReducer,
  users: appUsersReducer,
  orders: ordersReducer,
  receipts: receiptsReducers,
  banners: bannersReducers
});

const persistConfig = {
  key: "root",
  storage,
  version: 1,
  whitelist: ["user"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false });
  }
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
