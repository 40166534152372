import React from "react";

const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "grid",
        placeItems: "center"
      }}
    >
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
