export interface UserInitialState {
  isLoggedIn: boolean;
  userDetails: {
    admin: object | null;
    token: string;
  };
}

export const userInitialState = {
  isLoggedIn: false,
  userDetails: {
    admin: {},
    token: ""
  }
};

export const appUsersInitialState = {
  isLoading: false,
  users: null,
  searchString: "",
  page: 1
};

export const storesInitialState = {
  isLoading: true,
  stores: null,
  searchString: "",
  index: 0,
  limit: 10,
  totalCount: 0
};

export const receiptsInitialState = {
  isLoading: true,
  receipts: [],
  searchString: "",
  index: 0,
  limit: 10,
  totalCount: 0
};

export const bannersInitialState = {
  isLoading: true,
  banners: []
};

export const promotionsInitialState = {
  isLoading: true,
  promotions: null,
  searchText: "",
  filters: {
    endDate: "",
    store: "",
    status: ""
  },
  index: 0,
  limit: 10,
  totalCount: 0,
  currentPrize: {},
  currentRaffle: {}
};

export const ordersInitialState = {
  isLoading: true,
  orders: null
};

export const combinedInitialState = {
  user: userInitialState
};

export const stateToBePersisted = ["user"];
