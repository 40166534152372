import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Rootlayout from "./layout/rootLayout";
import Login from "./Pages/Login/login";
import LogoOnlyLayout from "./layout/logoOnlylayout";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import ProtectedRoute from "./utils/ProtectedRoute";
import ProtectedRouteWithSuspense from "./ProtectedRouteWithSuspense";
import { RootState } from "./store";
import { Suspense, lazy } from "react";
import Loader from "./utils/CustomComponents/Loader";
import ScrollToTop from "./utils/ScrollToTop";

const Home = lazy(() => import("./Pages/Home"));
const Stores = lazy(() => import("./Pages/Stores"));
const Products = lazy(() => import("./Pages/Products"));
const Users = lazy(() => import("./Pages/Users"));
const Orders = lazy(() => import("./Pages/Orders"));
const Receipts = lazy(() => import("./Pages/Receipts"));
const Promotions = lazy(() => import("./Pages/Promotions"));
const Notifications = lazy(() => import("./Pages/Notifications"));
const Support = lazy(() => import("./Pages/Support"));
const CreateNewStore = lazy(() => import("./Pages/Stores/CreateNewStore"));
const AddPromotion = lazy(() => import("./Pages/Promotions/AddPromotion"));
const EditPromotion = lazy(() => import("./Pages/Promotions/EditPromotion"));
const UpdateStore = lazy(() => import("./Pages/Stores/UpdateStore"));
const AddProduct = lazy(() => import("./Pages/Products/AddProduct"));
const EditProduct = lazy(() => import("./Pages/Products/EditProduct"));
const Banners = lazy(() => import("./Pages/Banners"));

const HomePageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <Home />
  </ProtectedRouteWithSuspense>
);

const StoresPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <Stores />
  </ProtectedRouteWithSuspense>
);

const ProductsPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <Products />
  </ProtectedRouteWithSuspense>
);
const EditStorePageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <UpdateStore />
  </ProtectedRouteWithSuspense>
);
const EditProductsPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <EditProduct />
  </ProtectedRouteWithSuspense>
);

const UsersPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <Users />
  </ProtectedRouteWithSuspense>
);

const OrdersPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <Orders />
  </ProtectedRouteWithSuspense>
);

const ReceiptsPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <Receipts />
  </ProtectedRouteWithSuspense>
);

const PromotionsPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <Promotions />
  </ProtectedRouteWithSuspense>
);

const AddPromotionsPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <AddPromotion />
  </ProtectedRouteWithSuspense>
);

const EditPromotionPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <EditPromotion />
  </ProtectedRouteWithSuspense>
);

const NotificationsPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <Notifications />
  </ProtectedRouteWithSuspense>
);

const AddStoreWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <CreateNewStore />
  </ProtectedRouteWithSuspense>
);
const AddProductWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <AddProduct />
  </ProtectedRouteWithSuspense>
);

const BannersPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <Banners />
  </ProtectedRouteWithSuspense>
);

const SupportPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <Support />
  </ProtectedRouteWithSuspense>
);

const LoginPageWithFallBack = () => (
  <Suspense fallback={<Loader />}>
    <Login />
  </Suspense>
);

const PageRouter = () => {
  const admin = useSelector((state: RootState) => state.user);
  const isLoggedIn = !isEmpty(admin) && !isEmpty(admin?.userDetails?.admin);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Rootlayout />
            </ProtectedRoute>
          }
        >
          <Route path="" element={<HomePageWithFallback />} />
          <Route path="stores" element={<StoresPageWithFallback />} />
          <Route path="stores/new" element={<AddStoreWithFallback />} />
          <Route
            path="stores/:id/products"
            element={<ProductsPageWithFallback />}
          />
          <Route
            path="stores/:id/edit"
            element={<EditStorePageWithFallback />}
          />
          <Route
            path="stores/:id/products/:productId/edit"
            element={<EditProductsPageWithFallback />}
          />
          <Route
            path="stores/:id/products/new"
            element={<AddProductWithFallback />}
          />
          <Route path="users" element={<UsersPageWithFallback />} />
          <Route path="receipts" element={<ReceiptsPageWithFallback />} />
          <Route
            path="notifications"
            element={<NotificationsPageWithFallback />}
          />
          <Route path="orders" element={<OrdersPageWithFallback />} />
          <Route path="promotions" element={<PromotionsPageWithFallback />} />
          <Route path="banners" element={<BannersPageWithFallback />} />
          <Route
            path="promotions/new"
            element={<AddPromotionsPageWithFallback />}
          />
          <Route
            path="promotions/:id/edit"
            element={<EditPromotionPageWithFallback />}
          />
          <Route path="support" element={<SupportPageWithFallback />} />
        </Route>
        <Route path="/" element={<LogoOnlyLayout />}>
          <Route
            path="login"
            element={
              !isLoggedIn ? <LoginPageWithFallBack /> : <Navigate to="/" />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default PageRouter;
