import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../store";
import { isEmpty } from "lodash";

interface Props {
  children: any;
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const admin = useSelector((state: RootState) => state.user);
  const isLoggedIn = !isEmpty(admin) && !isEmpty(admin?.userDetails?.admin);

  return isLoggedIn ? <>{children}</> : <Navigate to="/login" />;
};

export default ProtectedRoute;
