// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_signupScreen__Fx\\+v6 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_signupScreen__Fx\\+v6 .login_leftSection__e9vG3 {
  background: #006cea;
  width: 35%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_signupScreen__Fx\\+v6 .login_rightSection__nYE9H {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  height: 100%;
}
.login_signupScreen__Fx\\+v6 .login_rightSection__nYE9H > div {
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: center;
  gap: 2rem;
}

.login_formContainer__svpq8 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Login/login.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAAE;EACE,mBAAA;EACA,UAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;EACA,YAAA;AAEJ;AADI;EACE,aAAA;EACA,sBAAA;EACA,UAAA;EACA,mBAAA;EACA,SAAA;AAGN;;AAEA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF","sourcesContent":[".signupScreen {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  .leftSection {\n    background: #006cea;\n    width: 35%;\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  .rightSection {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 65%;\n    height: 100%;\n    & > div {\n      display: flex;\n      flex-direction: column;\n      width: 40%;\n      align-items: center;\n      gap: 2rem;\n    }\n  }\n}\n\n.formContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signupScreen": `login_signupScreen__Fx+v6`,
	"leftSection": `login_leftSection__e9vG3`,
	"rightSection": `login_rightSection__nYE9H`,
	"formContainer": `login_formContainer__svpq8`
};
export default ___CSS_LOADER_EXPORT___;
