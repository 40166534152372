import { useState } from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import styles from "./login.module.scss";
import axios from "axios";
import { useDispatch } from "react-redux";
import { signIn } from "../../utils/Redux/userSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import toast from "react-hot-toast";

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string | undefined>("");
  const [password, setPassword] = useState<string | undefined>("");
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/auth/adminLogin`,
        { email, password }
      );
      const adminDetails = res.data.content;
      dispatch(
        signIn({
          userDetails: { ...adminDetails }
        })
      );
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message || "Unknown error";
      toast.error(errorMsg);
    }
  };

  return (
    <div>
      <Typography variant="heading2">Login to your account</Typography>
      <form className={styles.formContainer} onSubmit={handleLogin}>
        <div>
          <Typography variant="body1">Your Email:</Typography>
          <TextField
            fullWidth
            type="text"
            placeholder="Youremail@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <Typography variant="body1">Password:</Typography>
          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            placeholder="********"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {!showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <Button variant="contained" fullWidth type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default Login;
