/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import classes from "./rootlayout.module.scss";
import { useEffect } from "react";
import { fetchStoresData } from "../utils/Redux/storesSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { AppDispatch } from "../store";
import { fetchUsersAsync } from "../utils/Redux/appUsersSlice";

const Rootlayout = () => {
  const dispatch = useDispatch<AppDispatch>();

  const successCallback = () => {};
  const errorCallback = (error: string) => {
    toast.error(error);
  };
  const input = {
    successCallback,
    errorCallback,
    value: {}
  };

  useEffect(() => {
    dispatch(fetchStoresData(input));
    dispatch(fetchUsersAsync(input));
  }, []);

  return (
    <div className={classes.rootlayout}>
      <Navbar />
      <div className={classes.rootStyle}>
        <Sidebar />
        <div className={classes.mainSection}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Rootlayout;
