import React, { Suspense } from "react";
import ProtectedRoute from "./utils/ProtectedRoute";
import Loader from "./utils/CustomComponents/Loader";

interface Props {
  children: any;
}

const ProtectedRouteWithSuspense: React.FC<Props> = ({ children }) => {
  return (
    <Suspense fallback={<Loader />}>
      <ProtectedRoute>{children}</ProtectedRoute>
    </Suspense>
  );
};

export default ProtectedRouteWithSuspense;
