import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { receiptsInitialState } from "./states";
import { RootState } from "../../store";
import axios, { AxiosError } from "axios";

const initialState = receiptsInitialState;

interface FetchReceiptsInput {
  successCallback: () => void;
  errorCallback: (error: string) => void;
  value: object;
}

export const fetchReceiptsAsync = createAsyncThunk(
  "receipts/async",
  async (input: FetchReceiptsInput, thunkApi) => {
    const { successCallback, errorCallback } = input;
    const { rejectWithValue } = thunkApi;
    const state = thunkApi.getState() as unknown as RootState;
    const user = state.user;
    const config = {
      headers: {
        authorization: `Bearer ${user?.userDetails?.token}`,
      },
    };
    try {
      const receipts = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/receipts/getTotalReceipts`,
        config
      );
      successCallback();
      return receipts.data.content;
    } catch (error: object | any) {
      const errorMsg = (error as AxiosError).message || "Unknown Error";
      errorCallback(errorMsg);
      return rejectWithValue(errorMsg);
    }
  }
);

export const ReceiptSlice = createSlice({
  name: "receipts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReceiptsAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchReceiptsAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.receipts = action.payload as any;
    });
    builder.addCase(fetchReceiptsAsync.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default ReceiptSlice.reducer;
