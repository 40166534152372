import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { promotionsInitialState } from "./states";
import { RootState } from "../../store";
import axios, { AxiosError } from "axios";

const initialState = promotionsInitialState;

interface FetchStoresInput {
  successCallback: () => void;
  errorCallback: (error: string) => void;
  value: object;
}

export const fetchPromotionsData = createAsyncThunk(
  "promotions/async",
  async (input: FetchStoresInput, thunkApi) => {
    const { successCallback, errorCallback } = input;
    const { rejectWithValue } = thunkApi;
    const state = thunkApi.getState() as unknown as RootState;
    const user = state.user;
    const promotion = state.promotions;
    const filters = state.promotions.filters;
    const config = {
      headers: {
        authorization: `Bearer ${user?.userDetails?.token}`
      }
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/promotions/getAll?search=${promotion.searchText}&store=${filters.store}&status=${filters.status}&endDate=${filters.endDate}&index=${promotion.index}&limit=${promotion.limit}`,
        config
      );
      successCallback();
      return {
        promotions: response.data.content,
        totalCount: response.data.totalPromotions
      };
    } catch (error: object | unknown) {
      const errorMsg = (error as AxiosError).message || "Unknown Error";
      errorCallback(errorMsg);
      return rejectWithValue(errorMsg);
    }
  }
);

const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    searchPromotions: (state, action) => {
      state.searchText = action.payload;
    },
    setStatusFilter: (state, action) => {
      state.filters.status = action.payload;
    },
    setEndDateFilter: (state, action) => {
      state.filters.endDate = action.payload;
    },
    setStoreFilter: (state, action) => {
      state.filters.store = action.payload;
    },
    clearFilters: (state) => {
      state.filters.status = "";
      state.filters.endDate = "";
      state.filters.store = "";
    },
    setIndex: (state, action) => {
      state.index = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setCurrentPrize: (state, action) => {
      state.currentPrize = action.payload;
    },
    setCurrentRaffle: (state, action) => {
      state.currentRaffle = action.payload;
    },
    removeCurrentRaffle: (state) => {
      state.currentRaffle = {};
    },
    removeCurrentPrize: (state) => {
      state.currentPrize = {};
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPromotionsData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPromotionsData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.promotions = action.payload.promotions;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(fetchPromotionsData.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const {
  searchPromotions,
  setStatusFilter,
  setEndDateFilter,
  setStoreFilter,
  clearFilters,
  setIndex,
  setLimit,
  setCurrentPrize,
  setCurrentRaffle,
  removeCurrentRaffle,
  removeCurrentPrize
} = promotionsSlice.actions;

export default promotionsSlice.reducer;
