import { Outlet } from "react-router-dom";
import classes from "../Pages/Login/login.module.scss";
import instawinnerlogo from "../Assets/Instawinnerlogo.png";
import { Stack, Typography } from "@mui/material";

const LogoOnlyLayout = () => {
  return (
    <div className={classes.signupScreen}>
      <div className={classes.leftSection}>
        <Stack alignItems={"center"} gap={3}>
          <img
            src={instawinnerlogo}
            alt="instawiinerlogo"
            style={{ width: "180px" }}
          />
          <Typography variant="heading1" sx={{ color: "#fff" }}>
            Insta Winner
          </Typography>
        </Stack>
      </div>
      <div className={classes.rightSection}>
        <Outlet />
      </div>
    </div>
  );
};

export default LogoOnlyLayout;
