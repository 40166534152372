import { ReactNode } from "react";
import { Toaster } from "react-hot-toast";

interface ToastProviderProps {
  children: ReactNode;
}

export const successToastOptions = {
  duration: 3000,
  position: "top-right",
  style: {
    background: "#4caf50",
    color: "#fff",
  },
};

export const errorToastOptions = {
  duration: 4000,
  position: "top-right",
  style: {
    background: "#f44336",
    color: "#fff",
  },
};

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  return (
    <>
      <Toaster />
      {children}
    </>
  );
};

export default ToastProvider;
