import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { bannersInitialState } from "./states";
import { RootState } from "../../store";
import axios from "axios";

const initialState = bannersInitialState;

interface FetchBannersInput {
  successCallback: () => void;
  errorCallback: (error: string) => void;
  value: object;
}

export const fetchBannersAsync = createAsyncThunk(
  "fetchBannersAsync",
  async (input: FetchBannersInput, thunkApi) => {
    const { successCallback, errorCallback } = input;
    const { rejectWithValue } = thunkApi;
    const state = thunkApi.getState() as unknown as RootState;
    const admin = state.user;
    const config = {
      headers: {
        authorization: `Bearer ${admin?.userDetails?.token}`
      }
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/banners/getBanners`,
        config
      );
      successCallback();
      return res.data.content;
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message || "Unknown Error";
      errorCallback(errorMsg);
      return rejectWithValue(error);
    }
  }
);

const bannersSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBannersAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBannersAsync.fulfilled, (state, action: any) => {
      state.isLoading = false;
      state.banners = action.payload;
    });
    builder.addCase(fetchBannersAsync.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default bannersSlice.reducer;
