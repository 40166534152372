import { blue, pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    heading1: true;
    heading2: true;
    title1: true;
    title2: true;
    body1: true;
    body2: true;
    caption: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariantsOptions {
    heading1?: React.CSSProperties;
    heading2?: React.CSSProperties;
    title1?: React.CSSProperties;
    title2?: React.CSSProperties;
    body1?: React.CSSProperties;
    body2?: React.CSSProperties;
    caption?: React.CSSProperties;
  }
}

export const appTheme = createTheme({
  palette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700]
    },
    secondary: {
      light: pink[300],
      main: "#11cb5f",
      dark: pink[700]
    },
    action: {
      active: "#fff"
    }
  },
  typography: {
    heading1: {
      fontSize: "2.75rem",
      fontWeight: 600,
      fontFamily: "Inter"
    },
    heading2: {
      fontSize: "2rem",
      fontWeight: 600,
      fontFamily: "Inter"
    },
    title1: {
      fontSize: "1.75rem",
      fontFamily: "Inter"
    },
    title2: {
      fontSize: "1.5rem",
      fontFamily: "Inter"
    },
    body1: {
      fontSize: "1.25rem",
      fontFamily: "Inter"
    },
    body2: {
      fontSize: "1rem",
      fontFamily: "Inter"
    },
    caption: {
      fontSize: "0.75rem",
      fontFamily: "Inter"
    }
  },
  components: {}
});
