import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ordersInitialState } from "./states";
import axios from "axios";

const initialState = ordersInitialState;

interface FetchOrdersInput {
  successCallback: () => void;
  errorCallback: (error: string) => void;
  value: object;
}

export const fetchOrdersAsync = createAsyncThunk(
  "fetchOrders/async",
  async (input: FetchOrdersInput, thunkApi) => {
    const { successCallback, errorCallback } = input;
    const { rejectWithValue } = thunkApi;
    const state = thunkApi.getState() as unknown as RootState;
    const admin = state.user;
    const config = {
      headers: {
        authorization: `Bearer ${admin?.userDetails?.token}`
      }
    };
    try {
      const orders = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/orders/getTotalOrders`,
        config
      );
      successCallback();
      return orders.data.content;
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message || "Unknown Error";
      errorCallback(errorMsg);
      return rejectWithValue(error);
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrdersAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrdersAsync.fulfilled, (state, action: any) => {
      state.isLoading = false;
      state.orders = action.payload;
    });
    builder.addCase(fetchOrdersAsync.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default ordersSlice.reducer;
