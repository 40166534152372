// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar_navbar__EicVc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 40px;
  position: fixed;
  width: 96%;
  border-bottom: 1px solid var(--gray-gray-300, #e1e3ea);
  background-color: var(--white-dark-white, #fff);
  z-index: 1000;
}
.navbar_navbar__EicVc .navbar_logoContainer__tiJ4p {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.navbar_navbar__EicVc .navbar_logoContainer__tiJ4p img {
  height: 40px;
}
.navbar_navbar__EicVc .navbar_rightSection__7sHfb {
  display: flex;
  align-items: center;
  gap: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/navbar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,UAAA;EACA,sDAAA;EACA,+CAAA;EACA,aAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAEJ;AADI;EACE,YAAA;AAGN;AAAE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAEJ","sourcesContent":[".navbar {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 80px;\n  padding: 0 40px;\n  position: fixed;\n  width: 96%;\n  border-bottom: 1px solid var(--gray-gray-300, #e1e3ea);\n  background-color: var(--white-dark-white, #fff);\n  z-index: 1000;\n  .logoContainer {\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n    img {\n      height: 40px;\n    }\n  }\n  .rightSection {\n    display: flex;\n    align-items: center;\n    gap: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `navbar_navbar__EicVc`,
	"logoContainer": `navbar_logoContainer__tiJ4p`,
	"rightSection": `navbar_rightSection__7sHfb`
};
export default ___CSS_LOADER_EXPORT___;
