// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCmhWtj4_zh-NcehjBYshzveF8-JnwNz2g",
  authDomain: "instawinner-63a3e.firebaseapp.com",
  projectId: "instawinner-63a3e",
  storageBucket: "instawinner-63a3e.appspot.com",
  messagingSenderId: "447142422673",
  appId: "1:447142422673:web:bf14ef41fa1a8a3c9f7730",
  measurementId: "G-93RTXMQJGB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
