// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rootlayout_rootlayout__mLm8R {
  min-height: 100%;
}
.rootlayout_rootlayout__mLm8R .rootlayout_rootStyle__hCMs6 {
  display: flex;
  overflow: hidden;
  padding-top: 80px;
}
.rootlayout_rootlayout__mLm8R .rootlayout_rootStyle__hCMs6 .rootlayout_mainSection__IVXIB {
  width: 85%;
  padding-left: 17%;
  min-height: 100vh;
  background-color: #f5f7fa;
  overflow-y: scroll;
}
.rootlayout_rootlayout__mLm8R .rootlayout_rootStyle__hCMs6 .rootlayout_mainSection__IVXIB ::-webkit-scrollbar {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/layout/rootlayout.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,aAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AAAI;EACE,UAAA;EACA,iBAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;AAEN;AADM;EACE,wBAAA;AAGR","sourcesContent":[".rootlayout {\n  min-height: 100%;\n  .rootStyle {\n    display: flex;\n    overflow: hidden;\n    padding-top: 80px;\n\n    .mainSection {\n      width: 85%;\n      padding-left: 17%;\n      min-height: 100vh;\n      background-color: #f5f7fa;\n      overflow-y: scroll;\n      ::-webkit-scrollbar {\n        display: none !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootlayout": `rootlayout_rootlayout__mLm8R`,
	"rootStyle": `rootlayout_rootStyle__hCMs6`,
	"mainSection": `rootlayout_mainSection__IVXIB`
};
export default ___CSS_LOADER_EXPORT___;
