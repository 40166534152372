import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storesInitialState } from "./states";
import axios, { AxiosError } from "axios";
import { RootState } from "../../store";

interface FetchStoresInput {
  successCallback: () => void;
  errorCallback: (error: string) => void;
  value: object;
}

const initialState = storesInitialState;

export const fetchStoresData = createAsyncThunk(
  "stores/async",
  async (input: FetchStoresInput, thunkApi) => {
    const { successCallback, errorCallback } = input;
    const { rejectWithValue } = thunkApi;
    const state = thunkApi.getState() as unknown as RootState;
    const user = state.user;
    const store = state.store;
    const config = {
      headers: {
        authorization: `Bearer ${user?.userDetails?.token}`
      }
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/stores/getAllStores?search=${store.searchString}&index=${store.index}&limit=${store.limit}`,
        config
      );
      successCallback();
      return {
        stores: response.data.content,
        totalCount: response.data.totalStores
      };
    } catch (error: object | unknown) {
      const errorMsg = (error as AxiosError).message || "Unknown Error";
      errorCallback(errorMsg);
      return rejectWithValue(errorMsg);
    }
  }
);

const storesSlice = createSlice({
  name: "Stores",
  initialState,
  reducers: {
    searchStores: (state, action) => {
      state.searchString = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setIndex: (state, action) => {
      state.index = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStoresData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchStoresData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.stores = action.payload.stores;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(fetchStoresData.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const { searchStores, setLimit, setIndex } = storesSlice.actions;

export default storesSlice.reducer;
