import classes from "./sidebar.module.scss";
import CampaignIcon from "@mui/icons-material/Campaign";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HelpIcon from "@mui/icons-material/Help";
import StorefrontIcon from "@mui/icons-material/Storefront";
import GroupIcon from "@mui/icons-material/Group";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useLocation, useNavigate } from "react-router-dom";
import CollectionsIcon from "@mui/icons-material/Collections";

const Tabs = [
  {
    id: 1,
    name: "Dashboard",
    icon: <DashboardIcon />,
    path: "/"
  },
  {
    id: 2,
    name: "Stores",
    icon: <StorefrontIcon />,
    path: "/stores"
  },
  {
    id: 3,
    name: "Users",
    icon: <GroupIcon />,
    path: "/users"
  },
  // {
  //   id: 4,
  //   name: "Receipt",
  //   icon: <ReceiptLongIcon />,
  //   path: "/receipts"
  // },
  {
    id: 5,
    name: "Orders",
    icon: <ShoppingCartIcon />,
    path: "/orders"
  },
  {
    id: 6,
    name: "Promotions",
    icon: <CampaignIcon />,
    path: "/promotions"
  },
  {
    id: 7,
    name: "Banners",
    icon: <CollectionsIcon />,
    path: "/banners"
  },
  // {
  //   id: 8,
  //   name: "Notifications",
  //   icon: <NotificationsIcon />,
  //   path: "/notifications"
  // },
  {
    id: 9,
    name: "Support",
    icon: <HelpIcon />,
    path: "/support"
  }
];

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const match = (path: String) => {
    const pathNameParts = location.pathname.split("/");
    const pathValue = pathNameParts[1] ? `/${pathNameParts[1]}` : "/";
    return path === pathValue;
  };

  return (
    <div className={classes.sidebar}>
      <div className={classes.tabsSection}>
        {Tabs.map((tab) => {
          const active = match(tab.path);
          return (
            <div
              className={classes.tab}
              key={tab.id}
              style={{
                backgroundColor: `${active ? "#eef6ff" : ""}`
              }}
              onClick={() => {
                navigate(tab.path);
              }}
            >
              {tab.icon}
              <p
                style={{
                  color: `${active ? "#3e97ff" : ""}`,
                  lineHeight: "24px"
                }}
              >
                {tab.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
