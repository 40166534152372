import classes from "./navbar.module.scss";
import { Button } from "@mui/material";
import { signOut as firebaseSignOut } from "firebase/auth";
import { useDispatch } from "react-redux";
import { signOut } from "../../utils/Redux/userSlice";
import { useNavigate } from "react-router-dom";
import BrandLogo from "../../Assets/Instawinnerlogo.png";
import { persistor } from "../../store";
import { auth } from "../../utils/firebaseConfig";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await firebaseSignOut(auth);
      dispatch(signOut());
      persistor.purge();
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={classes.navbar}>
      <div className={classes.logoContainer}>
        <img src={BrandLogo} alt="brandLogo" />
        <h1>Insta winner</h1>
      </div>
      <div className={classes.rightSection}>
        <Button onClick={handleSignOut} sx={{ color: "red" }}>
          Signout
        </Button>
      </div>
    </div>
  );
};

export default Navbar;
